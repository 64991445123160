/*eslint-disable */
import {isVisible, hasClass, triggerEvent} from '../../../../common/common';
import {matchMedia} from '../../../../common/matchMedia';
import getKeyCode from '@mands/mns-fe-pattern-library/lib/mns-fe-utilities/getKeyCode';
import { enterCodes } from '@mands/mns-fe-pattern-library/lib/mns-fe-utilities/keyCodes';

let wrpperElm, slides, paginationData, pagination, paginationButtons, slidesWrapper, slidesWrapperParentElm, newHeroBannerIconWrapper, 
controlElm, newHeroBannerPauseIcon, pauseIcon, newHeroBannerPlayIcon, playIcon, slidesCount, progressData, progressBar;
let width = window.outerWidth;

function intialize(wrpperElm) {
    setBannerLink(wrpperElm)
    slides = wrpperElm ? wrpperElm.getElementsByClassName('new-hero-banner__link') : '';
    paginationData = wrpperElm ? wrpperElm.getElementsByClassName('new-hero-banner__pagination'): '';
    pagination = paginationData ?  paginationData[0] : '';
    paginationButtons = pagination ? pagination.getElementsByClassName('pager-item') : '';
    slidesWrapper = wrpperElm ? wrpperElm.getElementsByClassName('new-hero-banner__large-wrapper')[0] : '';
    slidesWrapperParentElm = slidesWrapper && slidesWrapper.parentNode ? slidesWrapper.parentNode : '';
    newHeroBannerIconWrapper = wrpperElm ? wrpperElm.getElementsByClassName('new-hero-banner__icon-wrapper')[0]: '';
    controlElm = newHeroBannerIconWrapper ? newHeroBannerIconWrapper : '';
    newHeroBannerPauseIcon = wrpperElm ? wrpperElm.getElementsByClassName('new-hero-banner__pause-icon')[0] : '';
    pauseIcon = newHeroBannerPauseIcon ? newHeroBannerPauseIcon : '';
    newHeroBannerPlayIcon = wrpperElm ? wrpperElm.getElementsByClassName('new-hero-banner__play-icon')[0] : '';
    playIcon = newHeroBannerPlayIcon ? newHeroBannerPlayIcon : '';
    progressData = wrpperElm ? wrpperElm.getElementsByClassName('new-hero-banner__progress-bar'): '';
    progressBar = progressData ? progressData[0]: '';
    slidesCount = slides.length
}

function setBannerLink(wrpperElm) {
    slides = wrpperElm ? wrpperElm.getElementsByClassName('new-hero-banner__link') : '';
}

const media = matchMedia(5, 4, 3, 2, 1);
const containerWidth = 100;
const progressBarTime = 25;
const progressBarDelay = 0;
let navigation = 0, counter = 0, prevCounter = 0, AnimationStarted = false, suspended = false;
var animating = false, pauseSlider = false, xDown = null, yDown = null;

const Animation = {
    id: null,
    start: function(callback) {
        this.id = window.setInterval(() => {
            callback();
        }, progressBarTime);
        return this.id;
    },
    reset: function() {
        clearInterval(this.id);
        this.id = null;
    }
};

const sliderInit = {
    target: null,
    animationId: null,
    widthIncrement: 0.50,
    currentWidth: 0,
    element: null,

    reset() {
        this.currentWidth = 0;
        this.stop();
    },

    start() {
        this.animationId = Animation.start(this.move);
    },

    appendElement() {
        const bar = document.createElement('div');
        bar.className = 'bar';
        progressBar.appendChild(bar);
        sliderInit.target = bar;
    },

    pauseSlider() {
        if(pauseSlider) {
            pauseIconCode({pauseIconDisplay: 'none', playIconDisplay: 'block'});
            pauseSlider = false;
            this.reset();
        } else {
            if(isVisible(pauseIcon)) {
                this.start();
            }
        }
    },

    removeElement() {
        const child = this.element.querySelector('.bar');
        this.element.removeChild(child);
    },

    control() {
        //const deviceWidth = document.body.clientWidth; 
        counter !== 0 ? progressData[counter].classList.remove('hidden') : 
        progressData[0].classList.remove('hidden');
        progressBar = progressData[counter];
        this.appendElement();
        this.pauseSlider();
    },

    move() {
        var self = sliderInit;
        if (self.currentWidth >= containerWidth) {
            counter++;
            navigateSliderWithFadeEffect();
            self.currentWidth = 0;
        }
        if(navigation === 0) {
            self.currentWidth += self.widthIncrement;
            self.target.style.width = self.currentWidth + '%';
        }
    },

    stop() {
        Animation.reset(this.animationId);
        this.animationId = null;
    },

   suspend() {
        if(AnimationStarted) {
            Animation.reset(this.animationId);
            suspended = true;
            AnimationStarted = false;
        }
   }
};

function paginationClickHandler(elem, i) {
    elem.onclick = (function(i) {
        return function() {
            counter = i;
            stopAnimation();
            pauseSlider = true;
            navigateSliderWithFadeEffect();
        };
    })(i);
}

function addAccessibilityForPagination(elem, i) {
    elem.addEventListener('keydown', (e) => {
        const code = getKeyCode(e);
        if(enterCodes.indexOf(code) > -1) {
            counter = i;
            pauseSlider = true;
            navigateSliderWithFadeEffect();
        }
        if (e.which === 9 && i === 2) {
            if(e.shiftKey === true) {
                //stopAnimation();
            } else {
                if(suspended) {
                    startAnimation();
                }
            }
        }
    });

    elem.addEventListener('focus', (e) => {
        if(i === 2) {
            sliderInit.suspend();
        }
        e.target.style.backgroundColor = '#ffffff';
    });

    elem.addEventListener('focusout', (e) => {
        e.target.style.backgroundColor = 'inherit';
    });
    
}

const paginationCtrl = {
    addElement: function() {
        if(slidesCount === 1) {
            pagination.style.display = 'none';
        }
        for(let i = 0;i < slidesCount;i++) {
            const elem = document.createElement('a');
            elem.classList.add('pager-item');
            elem.setAttribute('role', 'button');
            elem.setAttribute('href', 'javascript:void(0)');
            const childElem = document.createElement('span');
            childElem.classList.add('page');
            addAccessibilityForPagination(elem, i);
            appendCurrentSlideText(elem, `Slide ${i+1}`);
            elem.appendChild(childElem);
            paginationClickHandler(elem, i);
            if (i === 0) {
                elem.classList.add('sel');
                appendCurrentSlideText(elem, '(Current Slide)');
            }
            pagination.appendChild(elem);
        }
        
    }
};

function appendCurrentSlideText(elem, text) {
    const childElm = document.createElement('span');
    childElm.classList.add('visuallyhidden');
    childElm.textContent = text;
    elem.appendChild(childElm);
}

function counterReset() {
    if (counter === slidesCount) {
        counter = 0;
    }
    else if (counter < 0) {
        counter = slidesCount - 1;
    }
}

function navigateSliderWithFadeEffect() {
    counterReset();

    if (progressBar) {
        sliderInit.reset();
    }
    
    slides[prevCounter].classList.remove('fade-in');
    slides[prevCounter].setAttribute('aria-hidden', 'true');
    navigation++;

    sliderInit.element = progressData[prevCounter];
    sliderInit.element.classList.add('hidden');

    const timeout = setTimeout(function() {
        pauseIcon.parentElement.style.cursor = 'pointer';
        sliderInit.removeElement();
        sliderInit.control();
        navigation = 0;
        clearTimeout(timeout);
    }, progressBarDelay);

    slides[counter].classList.add('fade-in');
    slides[counter].removeAttribute('aria-hidden');
    paginationButtons[prevCounter].classList.remove('sel');
    paginationButtons[prevCounter].removeChild(paginationButtons[prevCounter].querySelectorAll('.visuallyhidden')[1]);
    paginationButtons[counter].classList.add('sel');
    appendCurrentSlideText(paginationButtons[counter], '(Current Slide)');
    prevCounter = counter;
}

function pauseIconCode(style) {
    pauseIcon.style.display = style.pauseIconDisplay;
    playIcon.style.display = style.playIconDisplay;
}

function sliderControlHandler(event) {
    event.preventDefault();
    if(!isVisible(pauseIcon)) {
        startAnimation();
    } else {
        stopAnimation();
    }
}

function navigationHandler(len, elem) {
    if (counter !== len) {
        len === 0 ? counter--: counter++;
        navigateSliderWithFadeEffect();
    } else {
        counter = len === 0 ? 2 : 0;
        navigateSliderWithFadeEffect();
    }
    stopAnimation();
    slidesWrapperParentElm.querySelector('.liveregion').textContent = 'Slide ' + (counter + 1) + ' of ' + slidesCount;
}

function deviceChange() {
    if(!AnimationStarted) {
        if(window.outerWidth != width){
            width = window.outerWidth;
            startAnimation();
        }
    }
}

function getTouches(evt) {
    return evt.touches ||             // browser API
           evt.originalEvent.touches; // jQuery
}

function handleswipe(dir) {
    if(isVisible(pauseIcon)) {
        triggerEvent( controlElm, 'click' );
    }

    if(dir == 'left') {
        counter++;
    } else {
        counter--;
    }
    navigateSliderWithFadeEffect();
    stopAnimation();
}

function touchStartListeners(e) {
    if(animating) return;
    const firstTouch = getTouches(e)[0];                                      
    xDown = firstTouch.clientX;                                      
    yDown = firstTouch.clientY;
}

function touchMoveListeners(e) {
    if ( ! xDown || ! yDown ) {
        return;
    }

    var xUp = e.touches[0].clientX;                                    
    var yUp = e.touches[0].clientY;

    var xDiff = xDown - xUp;
    var yDiff = yDown - yUp;

    if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
        if ( xDiff > 0 ) {
            /* left swipe */ 
            handleswipe('left');
        } else {
            /* right swipe */
            handleswipe('right');
        }                       
    } else {
        if ( yDiff > 0 ) {
            /* up swipe */ 
        } else { 
            /* down swipe */
        }                                                                 
    }
    /* reset values */
    xDown = null;
    yDown = null;
};

function startAnimation() {
    sliderInit.start();
    pauseIconCode({pauseIconDisplay: 'block', playIconDisplay: 'none'});
    AnimationStarted = true;
    suspended = false;
}

function stopAnimation() {
    sliderInit.stop();
    pauseIconCode({pauseIconDisplay: 'none', playIconDisplay: 'block'});
    AnimationStarted = false;
    suspended = false;
}

function addListeners() {
    const nextBtn = wrpperElm.getElementsByClassName('new-hero-banner__next-btn')[0];
    const prevBtn = wrpperElm.getElementsByClassName('new-hero-banner__prev-btn')[0];

    controlElm.addEventListener('click', sliderControlHandler);

    controlElm.addEventListener('keydown', (e) => {
        const code = getKeyCode(e);
        if(enterCodes.indexOf(code) > -1) {
            sliderControlHandler(e);
            if(isVisible(pauseIcon)) {
                pauseIcon.focus();
            }
            if(isVisible(playIcon)) {
                playIcon.focus();
            }
        }
    });

    prevBtn.addEventListener('click', () => {
        navigationHandler(0, nextBtn);
    });

    nextBtn.addEventListener('click', () => {
        navigationHandler(slidesCount - 1, prevBtn);
    });

    media.addListener(deviceChange);

    window.addEventListener("orientationchange", deviceChange);

    slidesWrapper.addEventListener('touchstart', touchStartListeners, false);

    slidesWrapper.addEventListener('touchmove', touchMoveListeners);

    slidesWrapper.addEventListener('touchend', function(e) {
    });

    slidesWrapperParentElm.addEventListener('mouseenter', (e) => {
            sliderInit.suspend();
    });

    slidesWrapperParentElm.addEventListener('mouseleave', (e) => {
        if(suspended) {
            startAnimation();
        }
    });

    window.addEventListener('resize', function () {
        setTimeout(function () {
            setHeightCtaButtons(wrpperElm);
            setImageHeight();
        }, 10);
    });
}

function stopAnnimationOnFocus(btnWrappers) {
    Array.prototype.slice.call(btnWrappers).forEach(element => {
        const btnElm = element.getElementsByClassName('new-hero-banner__button-wrap')[0];
        btnElm.addEventListener('keydown', (e) => {
            if (e.which === 9) {
                if(e.shiftKey === true && suspended) {
                    startAnimation();
                }
            }
        });
        btnElm.addEventListener('focus', (e) => {
            sliderInit.suspend();
        });
    });    
}

function addAccessibility() {
    var liveregion = document.createElement('div');
    liveregion.setAttribute('aria-live', 'polite');
    liveregion.setAttribute('aria-atomic', 'true');
    liveregion.setAttribute('class', 'liveregion visuallyhidden');
    slidesWrapperParentElm.appendChild(liveregion);
    const btnWrappers = slidesWrapperParentElm.getElementsByClassName('new-hero-banner__ctas-wrapper');
    stopAnnimationOnFocus(btnWrappers);
    const btnmobileWrappers = slidesWrapperParentElm.getElementsByClassName('new-hero-banner__ctas-wrap');
    stopAnnimationOnFocus(btnmobileWrappers);
}

function clickableArea(slide) {
    const btnWrappers = slide.getElementsByClassName('new-hero-banner__ctas-wrapper');
    const deviceWidth = document.body.clientWidth;
    if (deviceWidth <= 768 && btnWrappers) {
        const btnClasses = btnWrappers[0].getElementsByClassName('new-hero-banner__button-wrap');
        for (let i = 0; i < btnClasses.length; i++) {
            btnClasses[i].addEventListener('click', e => {
                e.stopPropagation();
            });
        }
        btnWrappers[0].addEventListener('click', () => {
            window.location.href = btnClasses[0].href;
        });
    }
}

function findHHandWW() {
    setImageHeight();
}

function setHeightCtaButtons(wrpperElm) {
    const deviceWidth = document.body.clientWidth;
    const ctaWrapper = wrpperElm.getElementsByClassName('new-hero-banner__ctas-wrap');
    const paginationEmts = wrpperElm.getElementsByClassName('new-hero-banner__pagination') ? wrpperElm.getElementsByClassName('new-hero-banner__pagination')[0]: '';
    let totalCtaWrapper = ctaWrapper.length;
    let maxLength = ctaWrapper[0].getElementsByClassName('new-hero-banner__button-wrap').length;
    if (deviceWidth < 768) {
        for (let i = 0; i < totalCtaWrapper; i++) {
            let btnLength = ctaWrapper[i].getElementsByClassName('new-hero-banner__button-wrap').length;
            if(btnLength > maxLength) {
                maxLength = btnLength;
            }
        }
        for (let i = 0; i < totalCtaWrapper; i++) {
            const space = maxLength === 1 ? 34: 50;
            ctaWrapper[i].style.height = `${(maxLength * 51) + space}px`;
        }
    } 
    if (paginationEmts) {
        if(deviceWidth < 768) {
            const papaginationSpace = maxLength === 1 ? 34: 64;
            paginationEmts.style.marginTop = `${(maxLength * 51) + papaginationSpace}px`;
            paginationEmts.style.paddingTop = '0';
        } else {
            paginationEmts.style.paddingTop = '16px';
            paginationEmts.style.marginTop = '0';
        }

        if(hasClass(paginationEmts, 'visibility')) {
            paginationEmts.style.paddingTop = '0';
        }
    }
}

function setImageHeight(onload = false) {
    const deviceWidth = document.body.clientWidth;
    const wrapElm = wrpperElm.getElementsByClassName('new-hero-banner__large-wrapper')[0];
    let hiddenElm = wrapElm.querySelector('.new-hero-banner__hidden');
    const imgElm = hiddenElm.querySelector('.nonie');
    imgElm.style.height = 'auto';
    if(deviceWidth < 1279 && deviceWidth > 1023 && !onload) {
        hiddenElm = wrapElm.querySelector('.new-hero-banner__hidden');
        hiddenElm.querySelector('.nonie').style.height = `${hiddenElm.offsetHeight - 3}px`;
    }

    if(onload) {
        var myImage = new Image();
        myImage.name = imgElm.src;
        myImage.src = imgElm.src;
        myImage.onload = findHHandWW;
    }
}

function addLinkToImage() {
    Array.prototype.slice.call(slides).forEach(slide => {
        const btnWrapper = slide.getElementsByClassName('new-hero-banner__ctas-wrap')[0];
        const link = btnWrapper.getElementsByClassName('new-hero-banner__button-wrap')[0];
        const imgLink = slide.getElementsByClassName('new-hero-banner__btn-link')[0];
        const description1 = slide.getElementsByClassName('new-hero-banner__description');
        const description2 = slide.getElementsByClassName('new-hero-banner__description-wrap');
        imgLink.href = link.href;
        if(description1.length > 0) {
            description1[0].href = link.href;
        }
        if(description2.length > 0) {
            description2[0].href = link.href;
        }
        clickableArea(slide);
    });
}

function heroCarousel(elem) {
    wrpperElm = elem;
    intialize(wrpperElm);
    paginationCtrl.addElement();
    sliderInit.control();
    addListeners();
    addAccessibility();
    setImageHeight(true);
    setHeightCtaButtons(elem);
    addLinkToImage();
    AnimationStarted = true;
}

function singleImage(elem) {
    setHeightCtaButtons(elem);
    window.addEventListener('resize', function () {
        setHeightCtaButtons(elem);
        addLinkToImage();
    }, 10);
}

export {
    singleImage,
    heroCarousel,
    addLinkToImage,
    setBannerLink
};