/*eslint-disable no-extra-parens, no-magic-numbers */
export function setHeightFirstTileImage() {
    const middleImage = document.getElementsByClassName('mosaic-hero__tile__middle');
    const firstImage = document.getElementsByClassName('mosaic-hero__tile__first');
    for (let i = 0; i < firstImage.length; i++) {
        const middleHeight = window.getComputedStyle(middleImage[0]).height;
        const midValue = parseFloat(middleHeight.replace('px', ''));
        if (midValue > 0) {
            firstImage[i].style.height = (parseFloat(middleHeight) * 2).toFixed(2) + 'px';
        }
        else {
            firstImage[i].style.height = 'auto';
        }
    }
}

window.addEventListener('resize', function () {
    setHeightFirstTileImage();
});
