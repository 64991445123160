import Component from '@mands/mns-fe-pattern-library/lib/mns-fe-patterns/components/base/component';
import keyCodes from '@mands/mns-fe-pattern-library/lib/mns-fe-utilities/keyCodes';
import getKeyCode from '@mands/mns-fe-pattern-library/lib/mns-fe-utilities/getKeyCode';
import Accordion from '@mands/mns-fe-pattern-library/lib/mns-fe-patterns/components/accordion';

export default class Overlay extends Component {
    constructor(options) {
        if(!options.overlayId) {
            throw new Error('Missing option: overlayId');
        }
        super();
        const focusableElementsSelector = 'button, [href], input, select, textarea, [tabindex]:not([tabindex=\'-1\'])';

        this.overlay = document.getElementById(options.overlayId);
        this.openTrigger = options.openTriggerId;
        this.closeTrigger = this.overlay.querySelector('.overlay-content__close-trigger');
        this.contentHolder = this.overlay.querySelector('.overlay-content');
        this.bodysChildElements = Array.prototype.slice.call(document.body.childNodes).filter(element => element !== this.overlay && element.nodeType !== Node.TEXT_NODE);
        this.visible = false;
        this.animatedOpen = this.overlay.classList.contains('overlay--fly-out');
        this.backdrop = document.querySelector(`.overlay-backdrop--${options.overlayId}`);

        const overlayFocusableElements = Array.prototype.slice.call(this.overlay.querySelectorAll(focusableElementsSelector));
        const allFocusableElements = Array.prototype.slice.call(document.querySelectorAll(focusableElementsSelector));
        this.focusableElementsToHide = allFocusableElements.filter(element =>
            overlayFocusableElements.indexOf(element) === -1
        );

        this.addEventListeners();
        this.enableAccordion();
    }

    addEventListeners() {
        if(this.openTrigger) {
            this.openTrigger.addEventListener('click', () => this.open());
            this.openTrigger.addEventListener('keydown', e => {
                const code = e.keyCode ? e.keyCode : e.which;
                if(code === 13) {
                    e.preventDefault();
                    this.open();
                    this.closeTrigger.focus();
                }
            });
        }
        this.closeTrigger.addEventListener('click', event => this.close(event));
        this.overlay.addEventListener('keydown', event => this.close(event));
        this.overlay.addEventListener('click', event => this.close(event));
        this.overlay.querySelector('.overlay-dialog').addEventListener('click', event => event.stopPropagation());
        this.overlay.addEventListener('webkitTransitionEnd', this.finishedAnimating.bind(this));
        
    }

    enableAccordion() {
        const accordionId = this.contentHolder.querySelector('.accordion');
        new Accordion(accordionId);
        this.accordions = accordionId.getElementsByClassName('accordion__section');
        this.removeFocus();
    }

    removeFocus() {
        const accordionsLength = this.accordions.length;
        for (let i = 0; i < accordionsLength; i++) {
            this.accordions[i].addEventListener('mousedown', event => {
                const target = event.currentTarget;
                target.classList.add('no-outline');
                target.querySelector('.accordion__section__header').classList.add('no-outline');
            });

            this.accordions[i].addEventListener('keyup', event => {
                const code = event.keyCode ? event.keyCode : event.which;
                if (code === 9) {
                    const target = event.currentTarget;
                    target.classList.remove('no-outline');
                    target.querySelector('.accordion__section__header').classList.remove('no-outline');
                }
            });
        }

        this.accordions[accordionsLength-1].addEventListener('keydown', event => {
            const code = event.keyCode ? event.keyCode : event.which;
            if (!event.shiftKey && code === 9) {
                event.preventDefault();
                const removeButton = event.currentTarget.parentNode.parentNode.parentNode.querySelector('.overlay-content__close-trigger');
                removeButton.setAttribute("tabindex", "0");
                removeButton.focus();
            }
        });

        this.closeTrigger.addEventListener('keydown', event => {
            const code = event.keyCode ? event.keyCode : event.which;
            if (event.shiftKey && code === 9) {
                event.preventDefault();
                const accordionSections = event.currentTarget.parentNode.parentNode.getElementsByClassName('accordion__section');
                accordionSections[accordionSections.length - 1].setAttribute("tabindex", "0");
                accordionSections[accordionSections.length - 1].focus();
            }
        });
    }

    finishedAnimating() {
        if (!this.overlay.classList.contains('overlay--open')) {
            this.overlay.style.visibility = 'hidden';
            this.backdrop.classList.remove('overlay-backdrop--animated');
        }
    }

    open() {
        this.visible = true;
        this.lastFocusedElement = document.activeElement;
        this.currentStyles = document.body.style.cssText;
        this.scrollContainer = document.scrollingElement || document.documentElement;
        this.scrollTop = this.scrollContainer.scrollTop;
        document.body.style.cssText = `position: fixed; height: 100%; width: 100%; top: -${this.scrollTop}px;`;
        this.overlay.classList.add('overlay--open');
        if (this.animatedOpen) {
            this.backdrop.classList.add('overlay-backdrop--animated');
            this.overlay.style.visibility = 'visible';
        }
        this.backdrop.classList.add('overlay-backdrop--show');
        //this.contentHolder.setAttribute('tabindex', '0');
        this.focusableElementsToHide.forEach(element => {
            if (element.getAttribute('tabindex')) {
                element.dataset.existingTabIndex = element.getAttribute('tabindex');
            }
            element.setAttribute('tabindex', '-1');
        });
        this.bodysChildElements.forEach(element => element.setAttribute('aria-hidden', 'true'));
    }

    close(event) {
        const keyCode = getKeyCode(event);

        if (this.visible && (!keyCode || keyCodes.escapeCodes.indexOf(keyCode) > -1)) {
            this.visible = false;
            const width = document.body.clientWidth;
            document.body.style.cssText = this.currentStyles;
            document.body.style.removeProperty('top');
            document.body.style.removeProperty('position');
            this.scrollContainer.scrollTop = this.scrollTop;
            this.overlay.classList.remove('overlay--open');
            this.backdrop.classList.remove('overlay-backdrop--show');
            this.contentHolder.setAttribute('tabindex', '-1');
            this.focusableElementsToHide.forEach(element => element.setAttribute('tabindex', element.dataset.existingTabIndex || '0'));
            this.bodysChildElements.forEach(element => element.removeAttribute('aria-hidden'));
            if(width > 1024) {
                this.lastFocusedElement.focus();
            }
        }
    }
}
