/* eslint-disable no-magic-numbers, complexity */
import {addClass} from '../../../../common/common.js';
import OffersCarousel from './carousel';

function centerElm(carsouels, deviceWidth, limit) {
    if(carsouels.length > 0 && carsouels.length < limit && deviceWidth >= 768) {
        addClass(carsouels[0].parentNode, 'center-element');
        if(carsouels.length === 1) {
            addClass(carsouels[0].parentNode, 'one-product');
        }
    }
}

function getProductElm(deviceWidth, wrapperElement) {
    const limit = deviceWidth >= 768 ? 5: 2;
    const carsouels = deviceWidth >= 1280 ? wrapperElement.getElementsByClassName('promo__desktop-carousel')[0].getElementsByClassName('carousel__slide-product') : wrapperElement.getElementsByClassName('promo__mobile-carousel')[0].getElementsByClassName('carousel__slide-product');
    centerElm(carsouels, deviceWidth, limit);
}


function removeNavigationHandler(options) {
    const deviceWidth = document.body.clientWidth;
    const wrapperElement = document.getElementById(options.id);
    getProductElm(deviceWidth, wrapperElement);
    window.addEventListener('resize', function() {
        const deviceWidth = document.body.clientWidth;
        getProductElm(deviceWidth, wrapperElement);
    });
}

function addAccessibilityFeatures(element) {
    const carouselSlides = element.getElementsByClassName('carousel__slide');
    if (carouselSlides.length > 1) {
        const nextCarouselSlide = carouselSlides[1];
        const nextCarouselSlideProduct = nextCarouselSlide.getElementsByClassName('product')[0];
        nextCarouselSlideProduct.addEventListener('focus', function () {
            element.querySelector('.carousel__next').click();
        });
        const firstCarouselSlide = carouselSlides[0];
        const firstCarouselSlideProduct = firstCarouselSlide.getElementsByClassName('product');
        var lastChild = firstCarouselSlideProduct[firstCarouselSlideProduct.length - 1];
        lastChild.addEventListener('focus', function () {
            element.querySelector('.carousel__previous').click();
        });
        const svgs = element.getElementsByTagName('svg');
        for (let j = 0; j < svgs.length; j++) {
            svgs[j].setAttribute('focusable', false);
        }
    }
}

export function renderOffersComponent(offersComponents) {
    const max = 10;
    const componentsLength = offersComponents.length;
    for(let i=0; i<componentsLength; i++) {
        let increment = i+1;
        if(increment > max) {
            increment = max * increment;
        }
        const options = { id: `offersCarousel${increment}` };
        new OffersCarousel(options);
        removeNavigationHandler(options);
        addAccessibilityFeatures(offersComponents[i]);
    }
}