/*eslint-disable camelcase, no-undef, complexity*/

function validURL(myURL) {
    const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ //port
    '(\\?[;&amp;a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$', 'i');
    return pattern.test(myURL);
}

export function anchoredNavigation(elements) {
    for (let i = 0, n = elements.length; i < n; i++) {
        elements[i].addEventListener('click', (e) => {
            var l3Menu = null, l3Url= elements[i].firstElementChild.getAttribute('href');
            var myURL = validURL(elements[i].firstElementChild.getAttribute('href'));
            if(!myURL){
                var utagName = 'hpnav_icon_' + elements[i].getElementsByTagName('h3')[0].innerHTML;
                utag.link({link_name : 'homepage nav:icon', gnav_link : utagName});
                e.preventDefault();
                var headerElement = document.querySelector('.header');
                if (l3Url.indexOf('/c/') !== -1) {
                    l3Menu = headerElement.querySelector('a[href*="' + l3Url + '"]');
                    if(l3Menu){
                        l3Menu.classList.add('navigation-is-active-anchor');
                    }
                    var menu =  headerElement.querySelector('.navigation-res-nav-header__item--menu');
                    menu.click();
                } else if(l3Url.indexOf('/l/') !== -1){
                    var l1Url = l3Url.split('/');
                    l3Menu = headerElement.querySelector('a[href*="/c/' + l1Url[2] + '"]');
                    if(l3Menu){
                        l3Menu.classList.add('navigation-is-active-anchor');
                    }
                    l3Url = l3Url.indexOf('-n-') !== -1 ? l3Url.split('-n-')[0] : l3Url;
                    window.hpl3Url = l3Url;
                    menu =  headerElement.querySelector('.navigation-res-nav-header__item--menu');
                    menu.click();
                    window.hpl3Url = null;
                }
                if(l3Menu){
                    l3Menu.classList.remove('navigation-is-active-anchor');
                }
            }
            if (l3Menu !== null) {
                const header = l3Menu.parentElement.parentElement.previousElementSibling;
                const rootEl = header.parentElement.parentElement.parentElement;
                rootEl.scrollTop = l3Menu.parentElement.offsetTop;
            }
        });
    }
}