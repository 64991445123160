/*eslint-disable*/

let playerDivs;
let playVideoElements;
let playerInfoList = [];
let players = new Array();
let curplayer;
let oneFeatureImageratio = 2 / 3;

export function initializeYouTubePlayers() {
    playerDivs = document.querySelectorAll(".player");
    playVideoElements = document.querySelectorAll('.play-video');

    if (playerDivs.length > 0) {
        Array.prototype.slice.call(playerDivs).forEach(function (e, i) {
            let playerInfo = {};
            let playerEltId = 'player-' + (i + 1);
            e.setAttribute('id', playerEltId);
            playerInfo.id = e.id;
            playerInfo.videoId = e.getAttribute('data-video-id');
            playerInfo.isUnMuted = false;
            playerInfoList.push(playerInfo);
        });

        Array.prototype.slice.call(playVideoElements).forEach(function (playVideoElement, i) {
            setCoverVideoHeight(playVideoElement);
            playVideoElement.addEventListener('click', function (e) {
                e.preventDefault();
            });
        });

        loadYouTubePlayerAPIAndInitializePlayers();
    }
}

function loadYouTubePlayerAPIAndInitializePlayers() {
    if (typeof (YT) == 'undefined' || typeof (YT.Player) == 'undefined') {
        let tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        let firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);


        window.onYouTubePlayerAPIReady = function () {
            onYouTubeIframeAPIReady();
        };
    } else {
        onYouTubeIframeAPIReady();
    }
}

function onYouTubeIframeAPIReady() {
    if (typeof playerInfoList === 'undefined') return;

    for (let i = 0; i < playerInfoList.length; i++) {
        curplayer = createPlayer(playerInfoList[i], playVideoElements[i]);
        players[i] = curplayer;
    }
}

function createPlayer(playerInfo, playVideoElement) {
    return new YT.Player(playerInfo.id, {
        videoId: playerInfo.videoId,
        playerVars: { 'rel': 0 },
        events: {
            'onReady': function (event) {
                playVideoElement.removeEventListener('click', function () { });
                playVideoElement.addEventListener('click', function () {
                    playVideoElement.parentElement.querySelector('.player').style.display = 'block';
                    playVideoElement.style.display = 'none';
                    setVideoContainerHeight(playVideoElement);
                    event.target.playVideo();
                });
            },
            'onStateChange': function (event) {
                if (event.data == YT.PlayerState.ENDED) {
                    event.target.seekTo(0);
                    event.target.stopVideo();
                }
            }
        }
    });
}

function setVideoContainerHeight(playVideoElement) {
    let videoContainer = playVideoElement.parentElement;
    let iframe = videoContainer.getElementsByClassName('video-container__video')[0];
    iframe.style.height = Math.ceil(videoContainer.clientWidth * oneFeatureImageratio) + 'px';
}

function setCoverVideoHeight(playVideoElement) {
    let videoContainer = playVideoElement.parentElement;
    let coverVideo = videoContainer.getElementsByClassName('one-feature__cover-video')[0];

    if (coverVideo) {
        coverVideo.style.height = Math.ceil(videoContainer.clientWidth * oneFeatureImageratio) + 'px';
    }
}

if(playVideoElements) {
    window.addEventListener('resize', function () {
        Array.prototype.slice.call(playVideoElements).forEach(function (elt, i) {
            setCoverVideoHeight(elt);
            setVideoContainerHeight(elt);
        });
    }, false);
}
