/*eslint-disable complexity */
import '@babel/polyfill';
import '../../common/lib/lazysizes.min';
import { bindShowHideLinks } from './components/showHideLinks';
import { anchoredNavigation } from './components/bucategoryNavigation';
import { redirectToButtonLink } from './components/homePagebanners';
import { automaticTransition } from './components/propositionBanner';
import { heroSlider } from './components/newHeroBanner';
import { DOM } from '../../common/browser';
import { imagesAnimation } from './components/newInPlusComponent';
import { callOutTansition } from './components/newSaleBanner';
import { callOutTextTansition } from './components/dlpSaleBanner';
import { disableFocusOnClick } from '../../common/disbaleOnClick';
import { renderOffersComponent } from './components/offersComponent';
import { initializeYouTubePlayers } from './components/oneFeatureComponent';
import { heroCarousel, singleImage, addLinkToImage, setBannerLink} from './components/heroCarousel';
import { backgroundColorChange, setFocusOnSpotlight } from './components/spotlight';
import { setHeightFirstTileImage } from './components/mosaicHero';
import ProductListCarousel from '@mands/mns-fe-pattern-library/lib/mns-fe-patterns/components/product-list-carousel';
import './components/shopbyBrandCarousel/index';
import * as DotComPlusMenu from '@mands/dot-com-plus-menu';
import {enableFlyout} from './components/flyout/launch';
import countdown from './components/countdownTimer';
import {initialize} from './components/shopbyCategory';

DOM.ready(() => {
    const imgLink = document.getElementsByClassName('newinplus-component__newintiles')[0];
    const slideWrapper = document.getElementsByClassName('hero-slider');
    const styleAndLiving = document.querySelector('.style-and-living');
    const newSaleBannerComponent = document.querySelector('.new-sale-banner');
    const dlpSaleBannerComponent = document.querySelector('.dlp-sale-banner');
    const offersComponents = document.getElementsByClassName('offer-component');
    const oneFeatureComponents = document.getElementsByClassName('one-feature__container');
    const heroCarouselWrapper = document.getElementsByClassName('hero-carousel');
    const mosaicHeroElement = document.getElementsByClassName('mosaic-hero');
    const prepositionBanner = document.getElementsByClassName('proposition-banner');
    const timerElement = document.getElementById('timer');

    // bind style and living show/hide links
    if (styleAndLiving) {
        bindShowHideLinks(
            styleAndLiving,
            'button',
            'style-and-living--show-more'
        );
    }

    if (mosaicHeroElement.length > 0) {
        setHeightFirstTileImage();
    }

    const pageUrl=window.location.href;
    let wraptext;
    let searchtext;
    let signouttext;

    if (pageUrl.includes('/ie'))
    {
        wraptext = '/RestoreOriginalUserSetInSession?catalogId=11551&wrapUp=true&langId=-24&wrapUpUrl=/webapp/wcs/stores/servlet/TopCategoriesDisplayView?catalogId=10051&langId=-24&storeId=10451&storeId=10451';
        searchtext='/ie/MSCSAdvancedSearchForm';
        signouttext='/Logoff?catalogId=11551&langId=-24&storeId=10451&URL=MSCSLogonForm';
    } else  {
        wraptext = '/RestoreOriginalUserSetInSession?catalogId=10051&wrapUp=true&langId=-24&wrapUpUrl=/webapp/wcs/stores/servlet/TopCategoriesDisplayView?catalogId=10051&langId=-24&storeId=10151&storeId=10151';
        searchtext='/MSCSAdvancedSearchForm';
        signouttext='/Logoff';
    }

    const dotComPlusUrls = {
        signOut: signouttext,
        customerSearch: searchtext,
        wrapup: wraptext
    };

    // bind style and living show/hide links
    if (styleAndLiving) {
        bindShowHideLinks(
            styleAndLiving,
            'button',
            'style-and-living--show-more'
        );
    }

    try {
        DotComPlusMenu.render('#dcplus', dotComPlusUrls);
    } catch (e) {
        throw `Could not load DotComPlusMenu: ${e}`;
    }

    //for BU category icons - only mobile view
    anchoredNavigation(
        document.querySelectorAll('.category-icon .promo__tile')
    );

    redirectToButtonLink(
        'main-banner__btn'
    );

    //Automatic transition for Proposition banner
    automaticTransition();

    if (slideWrapper.length > 0 && slideWrapper[0].getElementsByClassName('new-hero-banner__lg-image').length > 0) {
        heroSlider();
    }

    if(heroCarouselWrapper.length > 0) {
        for(let i=0; i < heroCarouselWrapper.length; i++) {
            if(heroCarouselWrapper[i].getElementsByClassName('new-hero-banner__lg-image').length > 1) {
                heroCarousel(heroCarouselWrapper[i]);
            }
            if(heroCarouselWrapper[i].getElementsByClassName('new-hero-banner__lg-image').length === 1) {
                setBannerLink(heroCarouselWrapper[i]);
                singleImage(heroCarouselWrapper[i]);
                addLinkToImage();
            }
        }
    }
    //image animation for new in plus component
    if (imgLink) {
        imagesAnimation(imgLink);
    }

    if (offersComponents.length > 0) {
        renderOffersComponent(offersComponents);
    }

    if (newSaleBannerComponent) {
        callOutTansition();
    }
    if (dlpSaleBannerComponent) {
        callOutTextTansition();
    }

    //One Feature - Video Players
    if (oneFeatureComponents) {
        initializeYouTubePlayers();
    }

    const hpComponents = document.querySelector('.hp-components').children;
    disableFocusOnClick(hpComponents);

    function spotlightHandler() {
        const spotlight = document.getElementsByClassName('spotlight');
        const spotlightLength = spotlight.length;
        if (spotlightLength > 0) {
            for (let i = 0; i < spotlightLength; i++) {
                const options = { id: `productCarousel-${i + 1}` };
                new ProductListCarousel(options);
                spotlight[i].getElementsByClassName('carousel__previous')[0].classList.add('show-nav');
                spotlight[i].getElementsByClassName('carousel__next')[0].classList.add('show-nav');
                backgroundColorChange(spotlight[i].querySelectorAll('.product'));

                window.addEventListener('resize', function () {
                    backgroundColorChange(spotlight[i].querySelectorAll('.product'), true);
                });
            }
            setFocusOnSpotlight();
        }
    }

    var firePageView = setInterval(() =>  {
        if (window.targetResponseTokens) {
            spotlightHandler();
            const twoFeatureComponents = document.getElementsByClassName('two-feature-component');
            if(twoFeatureComponents.length > 0) {
                twoFeatureComponents[twoFeatureComponents.length-1].setAttribute('id', 'AT-perso-content');
            }
            clearInterval(firePageView);
        }
    }, 100); //eslint-disable-line no-magic-numbers

    if(prepositionBanner.length > 0) {
        const flyoutTriggerElms = prepositionBanner[0].getElementsByClassName('flyoutTriggerElm');
        if(flyoutTriggerElms.length > 0) {
            for(let i=0; i<flyoutTriggerElms.length; i++) {
                const flyoutElm = flyoutTriggerElms[i].nextElementSibling.id;
                enableFlyout(flyoutTriggerElms[i], flyoutElm);
            }
        }
    }

    if(timerElement) {
        new countdown(timerElement);
    }

    initialize();

});
