/*eslint-disable camelcase, no-undef, no-empty-function, no-magic-numbers, complexity */
const createCarouselId = (carouselLocation = 'END') => `FUNH_AT_RECENT_${carouselLocation}_HP`;

let isClicked = false, isSwiped = false;

const isScrolledIntoView = el => {
    const rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};

const trackEvent = (carouselId, event, strokeId, skipPrefix) => {
    const utag = window.utag || {};

    const events = {
        'click': {
            'linkName': 'scroll click',
            'eventName': 'scroll'
        },
        'swipe': {
            'linkName': 'scroll swipe',
            'eventName': 'scroll'
        },
        'remove': {
            'linkName': 'remove item',
            'eventName': 'item_remove'
        },
        'undo': {
            'linkName': 'undo remove item',
            'eventName': 'undo_item_remove'
        },
        'present': {
            'linkName': 'present',
            'eventName': 'present'
        },
        'presentAndSeen': {
            'linkName': 'present',
            'eventName': ['carousel_present', 'carousel_seen']
        },
        'presentAndSeenWithScroll': {
            'linkName': 'present',
            'eventName': ['carousel_present', 'carousel_seen', 'auto_scroll_play']
        },
        'seen': {
            'linkName': 'seen',
            'eventName': 'seen'
        },
        'seenWithScroll': {
            'linkName': 'seen',
            'eventName': ['carousel_seen', 'auto_scroll_play']
        }
    };

    const utag_link_data = {
        link_name : `carousel:${events[event].linkName}`,
        event_name : skipPrefix ? [`${events[event].eventName}`] : `carousel_${events[event].eventName}`,
        carousel_id : carouselId
    };

    if(event === 'remove' || event === 'undo') {
        utag_link_data.product_stroke = [strokeId];
    }

    if(utag.link) {
        utag.link(utag_link_data);
    }
};

const onetimeEvent = (element, isRemovedOrUndo) => {
    const node = element.getElementsByClassName('carousel__next');
    function trackClickEvent(event) {
        isClicked = true;
        event.target.parentNode.removeEventListener(event.type, trackClickEvent);
        const { carouselIdWithTotalProducts } = gettotalProducts(event.target.parentNode.parentNode);
        trackEvent(carouselIdWithTotalProducts, 'click');
    }
    if(isRemovedOrUndo) {
        node[0].removeEventListener('click', trackClickEvent);
    }
    if(node.length > 0) {
        node[0].addEventListener('click', trackClickEvent);
    }
};

const touchEvent = (element, isRemovedOrUndo) => {

    let xDown, yDown;

    const getTouches = evt => evt.touches || evt.originalEvent.touches;

    function touchStartHandler(event) {
        const firstTouch = getTouches(event)[0];
        xDown = firstTouch.clientX;
        yDown = firstTouch.clientY;
    }

    function touchEventHandler(event) {
        if ( ! xDown || ! yDown ) {
            return;
        }

        const xUp = event.touches[0].clientX;
        const yUp = event.touches[0].clientY;
        const xDiff = xDown - xUp;
        const yDiff = yDown - yUp;

        if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {
            if ( xDiff > 0 ) {
                const { carouselIdWithTotalProducts } = gettotalProducts(event.target.parentNode.parentNode);
                trackEvent(carouselIdWithTotalProducts, 'swipe');
                isSwiped = true;
                element.removeEventListener('touchstart', touchStartHandler);
                element.removeEventListener('touchmove', touchEventHandler);
            }
        }

        xDown = null;
        yDown = null;
    }

    function touchEndHandler() {}

    if(isRemovedOrUndo) {
        element.removeEventListener('touchstart', touchStartHandler);
        element.removeEventListener('touchmove', touchEventHandler);
        element.removeEventListener('touchend', touchEndHandler);
    }

    element.addEventListener('touchstart', touchStartHandler, false);
    element.addEventListener('touchmove', touchEventHandler, {passive: false});
    element.addEventListener('touchend', touchEndHandler);
};

const checkElementInViewport = (element, carouselId,  IsOnPageLoad = true) => {
    const flag = isScrolledIntoView(element);
    if(flag) {
        IsOnPageLoad ? trackEvent(carouselId, 'presentAndSeen', undefined, true) : trackEvent(carouselId, 'seen');
    }

    if(IsOnPageLoad && !flag) {
        trackEvent(carouselId, 'present');
    }
    return flag;
};

function trackCarouselOnPageScroll(element, carouselId) {
    let isSeen;
    window.addEventListener('scroll', function fn() {
        isSeen = checkElementInViewport(element, carouselId, false);
        if(isSeen) {
            window.removeEventListener('scroll', fn);
        }
    });
}

function gettotalProducts(element) {
    const width = document.body.clientWidth;
    const wrapperElement = width > 1024 ? element.getElementsByClassName('carousel__slides-wrapper')[0] : element.getElementsByClassName('product__carousel-slides-wrapper')[0];
    const productElms = wrapperElement.getElementsByClassName('carousel__slide-product');
    const carouselId = createCarouselId();
    const carouselIdWithTotalProducts = `${carouselId}_${productElms.length}`;
    return {carouselId, wrapperElement, carouselIdWithTotalProducts, width};
}

function trackCarousel(element, isRemovedOrUndo) {
    const { carouselId, wrapperElement, carouselIdWithTotalProducts, width } = gettotalProducts(element);

    if(!isRemovedOrUndo) {
        const flag = checkElementInViewport(element, carouselIdWithTotalProducts);
        if(!flag) {
            trackCarouselOnPageScroll(element, carouselIdWithTotalProducts);
        }
    }

    if(!isClicked) {
        onetimeEvent(element, isRemovedOrUndo);
    }

    if(width < 1024 && wrapperElement && !isSwiped) {
        touchEvent(wrapperElement, isRemovedOrUndo);
    }
    return carouselId;
}

const analyticsFunctions = {trackCarousel, trackEvent, isScrolledIntoView};
export default analyticsFunctions;