/*eslint-disable no-magic-numbers, no-useless-concat,  no-extra-parens */
function changeBackgroundImage(tile, totalTiles) {
    const deviceWidth = document.body.clientWidth;
    const parentElm = tile.parentNode.parentNode;
    const imageurl = parentElm.getElementsByClassName('imageurl');
    const videoElem = parentElm.getElementsByClassName('video-js');
    const videoWrapperElem = parentElm.getElementsByClassName('shopby-category__animation');
    let currentTileIndex = deviceWidth < 768 ? 5 : 11;
    if (totalTiles < (currentTileIndex + 1)) {
        currentTileIndex = totalTiles - 1;
    }
    const currentTile = tile.parentNode.getElementsByClassName('shopby-category__tile')[currentTileIndex];
    const currentTileOffsetTop = currentTile.offsetTop;
    const currentTileClientHeight = currentTile.clientHeight;
    const parentElemOffsetTop = parentElm.offsetTop;
    const currentTilePosition = ((currentTileOffsetTop + currentTileClientHeight) - parentElemOffsetTop);
    const currentTileDeviceWidth = deviceWidth + 8;
    if (videoElem.length > 0) {
        videoWrapperElem[0].style.maxHeight = currentTilePosition + 'px';
        videoElem[0].style.height = deviceWidth > 1000 ? 'auto' : currentTilePosition + 'px';
    } else {
        parentElm.style.backgroundSize = `${currentTileDeviceWidth}px ` + `${currentTilePosition}px`;
        if (deviceWidth < 768) {
            parentElm.style.backgroundImage = `url(${imageurl[0].getAttribute('data-mobile-image')})`;
        } else {
            parentElm.style.backgroundImage = `url(${imageurl[0].getAttribute('data-desktop-image')})`;
        }
    }
}

function addBackgroundImage(tiles, totalTilesLength) {
    if (totalTilesLength > 0) {
        for (let j = 0; j < 11 && j< totalTilesLength; j++) {
            const img = tiles[j].getElementsByTagName('img')[0];
            img.onload = function () {
                changeBackgroundImage(tiles[0], totalTilesLength);
            }
        }
    }
}

function resizeHandler(tiles, totalTilesLength) {
    if (totalTilesLength >= 5) {
        changeBackgroundImage(tiles[0], totalTilesLength);
    }
}

export function initialize() {
    const shopByCategoryElement = document.getElementsByClassName('shopby-category');
    if(shopByCategoryElement.length > 0) {
        for (let i = 0; i < shopByCategoryElement.length; i++) {
            const tiles = shopByCategoryElement[i].getElementsByClassName('shopby-category__tile');
            const totalTilesLength = tiles.length;
            addBackgroundImage(tiles, totalTilesLength);
            window.addEventListener('resize', function () {
                resizeHandler(tiles, totalTilesLength)
            });
        }
    }
}
