export function imagesAnimation(imgLink) {
    const imgArr = document.getElementsByClassName('newinplus-component__newintiles--link');
    function fadeComplete() {
        imgLink.appendChild(imgArr[0]);
    }
    if(imgArr && imgArr.length>0) {
        for (let i = 0; i < imgArr.length; i++) {
            imgArr[i].addEventListener('animationend', fadeComplete, false);
        }
    }
    imgLink.classList.add('newinplus-component__animation');
}