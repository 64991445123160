/**
 * This module tracks and fires the tracking events.
 */

// Object that keeps track of the tracking events fired
var trackingArr = {};

/**
 * fire a tracking event & add to the tracking array
 * @param {*} option
 * @param {*} trackOnce -- optional parameter (default will be tracked once)
 */
export function trackAnalytics(option, trackOnce = true) {
    if(trackOnce && trackingArr[option]) {
        return;
    }
    trackingArr[option] = true;
    switch (option.toLowerCase()) {
        case 'show more' :
            window.utag.link({'link_name' : 'show more'});
            break;
        case 'show less' :
            window.utag.link({'link_name' : 'show less'});
            break;
    }
}

/**
 * Clear the tracking status, clear all if no param is passed
 * @param {*} option
 */
export function clearTracking(option) {
    option ? trackingArr[option] = false : trackingArr = {};
}

/**
 * Fetch the current tracking status
 */
export function getTrackingStatus() {
    return trackingArr;
}