/* eslint-disable no-invalid-this */
export function disableFocusOnClick(components) {
    Array.prototype.slice.call(components).forEach(function (component) {
        const linkObjects = component.getElementsByTagName('a');
        const buttonObjects = component.getElementsByTagName('button');

        const componentLinks = Object.keys(linkObjects).map(e => linkObjects[e]);
        const componentButtons = Object.keys(buttonObjects).map(e => buttonObjects[e]);

        const clickableElts = componentLinks.concat(componentButtons);

        if (component.classList.contains('alert-banner')) {
            clickableElts.push(component);
        }

        for (let i = 0; i < clickableElts.length; i++) {
            clickableElts[i].addEventListener('mousedown', function () {
                this.classList.add('no-outline');
                for (let j = 0; j < this.children.length; j++) {
                    if(this.children[j]) {
                        this.children[j].classList.add('no-outline');
                    }
                }
            });

            clickableElts[i].addEventListener('keyup', function () {
                if (event.keyCode === 9) {//eslint-disable-line no-magic-numbers
                    this.classList.remove('no-outline');
                    for (let j = 0; j < this.children.length; j++) {
                        if(this.children[j]) {
                            this.children[j].classList.remove('no-outline');
                        }
                    }
                }
            });
        }
    });
}