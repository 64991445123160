import Component from '@mands/mns-fe-pattern-library/lib/mns-fe-patterns/components/base/component';
import Carousel from '@mands/mns-fe-pattern-library/lib/mns-fe-patterns/components/carousel/index';
import Tabs from '@mands/mns-fe-pattern-library/lib/mns-fe-patterns/components/tabs/index';

export default class OffersCarousel extends Component {
    constructor(options) {
        super();
        const amountOfSlides = document.getElementById(options.id).querySelectorAll('.tabs-container .tabs-container__content .tabs__content').length;
        new Tabs(options);
        for (let i = 0; i < amountOfSlides; i++) {
            const id = `${options.id}${(i+1)}`;
            const option = { id, el: document.querySelector(`#${id}`), arrowStyle: 'shadow' };
            new Carousel(option);
            OffersCarousel.generateMobileProductList(id);
            OffersCarousel.hideControl(id);
        }
    }

    static generateMobileProductList(pid){
        const mobileCarousel = document.querySelector(`#mobile${pid} .product__carousel-slides-wrapper`);
        const innerProducts = Array.prototype.slice.call(document.querySelectorAll(`.promo__desktop-carousel #${pid} .carousel__slides-wrapper .carousel__slides-product-wrapper`));
        mobileCarousel.innerHTML = innerProducts.reduce((html, ul) => html + ul.innerHTML, '');
    }

    static hideControl(pid) {
        const productCarousel = document.getElementById(`${pid}`);
        const carouselSlidesLength = productCarousel.querySelectorAll('.carousel__slides-wrapper .carousel__slide').length;
        if(carouselSlidesLength === 1) {
            productCarousel.querySelector('.carousel__previous').style.display = 'none';
            productCarousel.querySelector('.carousel__next').style.display = 'none';
            productCarousel.querySelector('.carousel__pages').style.display = 'none';
        }
    }
}