export function callOutTextTansition() {
    window.addEventListener('DOMContentLoaded', function () {
        const stage = document.getElementsByClassName('dlp-sale-banner__callouts')[0];
        const arr = document.getElementsByClassName('dlp-sale-banner__callouts-link');
        function fadeComplete() {
            stage.appendChild(arr[0]);
        }
        if (arr.length > 1) {
            for (let i = 0; i < arr.length; i++) {
                arr[i].addEventListener('animationend', fadeComplete, false);
            }
        }
    }, false);
}
