/**
 * Bind button click event handlers to dom elements
 * @param {*} btnClass
 */

function redirectToButtonLink(btnClass) {
    const classname = document.getElementsByClassName(btnClass);
    if(classname) {
        Array.prototype.slice.call(classname).forEach(function(element) {
            element.addEventListener('click', (event) => {
                event.preventDefault();
                window.location = event.target.getAttribute('data-url');
            });
        });
    }
}

function disableClickEventFor3FeatureComponent() {
    const threeFeatureComponentElements = document.getElementsByClassName('three-feature-component');
    if(threeFeatureComponentElements && threeFeatureComponentElements.length>0) {
        const promoTileTitles = threeFeatureComponentElements[0].getElementsByClassName('promo__tile-title');
        if(promoTileTitles && promoTileTitles.length > 0) {
            for ( let i = 0; i < promoTileTitles.length; i++ ){
                promoTileTitles[i].addEventListener('click', function() {
                    event.preventDefault();
                });
            }
        }
    }
}

function disableClickEventForInspireMeTitle() {
    if(document.getElementsByClassName('inspire-me-content').length > 0) {
        const styleLivingTileElements = document.getElementsByClassName('inspire-me-content')[0].getElementsByClassName('style-living-tile');
        for ( let i = 0; i < styleLivingTileElements.length; i++ )  {
            styleLivingTileElements[i].getElementsByTagName('a')[0].removeAttribute('title');
            styleLivingTileElements[i].addEventListener('click', disableClick);
        }
    }
}

function disableClick() {
    const styleLivingtitleLength = 24;
    if(event.target.getAttribute('class').substr(0, styleLivingtitleLength)==='style-living-tile__title'){
        event.preventDefault();
    }
}

export {
    redirectToButtonLink,
    disableClickEventFor3FeatureComponent,
    disableClickEventForInspireMeTitle
};