import Overlay from './index';
export function enableFlyout(element, id) {
    element.addEventListener('click', e => {
        e.preventDefault();
    });

    const flyOutOptions = {
        overlayId: id,
        openTriggerId: element
    };
    try {
        new Overlay(flyOutOptions);
    }
    catch (e) {
        console.log(e);
    }
}