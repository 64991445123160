/*eslint-disable no-magic-numbers, complexity, no-invalid-this, no-extra-parens */
/*eslint-disable*/
const spotlight = document.getElementsByClassName('spotlight');
function backgroundColorChange(element, isWindowResize=false) {
    for (let i = 0; i < element.length; i++) {
        const image = element[i].querySelector('.product__image--view');
        const productDetailsWrapper = element[i].querySelector('.product__details__wrapper');
        if(isWindowResize){
            setHeightLandscapeImage(image, productDetailsWrapper);  
        } else {
            image.onload = function () {
                setHeightLandscapeImage(image, productDetailsWrapper);   
            };
        }
        
    }
}

function setHeightLandscapeImage(image, productDetailsWrapper) {
    const width = image.width;
    const height = image.height;
    const imgParent = image.parentNode.parentNode;
    if (width > height) {
        imgParent.style.height = `calc(100% - ${productDetailsWrapper.clientHeight}px)`;
        imgParent.parentNode.classList.add('landscape-image');
        console.log('imgParent.', productDetailsWrapper.clientHeight);
    }
}

function setFocusOnSpotlight() {
    for (let i = 0; i < spotlight.length; i++) {
        const carouselSlides = spotlight[i].getElementsByClassName('carousel__slide');
        const totalCarouselSlides = carouselSlides.length;
        for (let j = 0; j < totalCarouselSlides; j++) {
            if (j !== (totalCarouselSlides - 1)) {
                const currentCarouselSlide = carouselSlides[j];
                const lastCarouselSlideProduct = currentCarouselSlide.getElementsByClassName('product')[3];
                lastCarouselSlideProduct.setAttribute('slideindex', j);
                lastCarouselSlideProduct.setAttribute('spotlightindex', i);
                lastCarouselSlideProduct.addEventListener('keydown', function (e) {
                    const code = (e.keyCode ? e.keyCode : e.which);
                    if (!(e.shiftKey) && code === 9) {
                        const slideIndex = parseInt(this.getAttribute('slideindex'));
                        const spotlightIndex = parseInt(this.getAttribute('spotlightindex'));
                        setFocusOnProduct(spotlightIndex, slideIndex + 1, 0, '.carousel__next');
                        e.preventDefault();
                    }
                });
            }
            if (j > 0) {
                const currentCarouselSlide = carouselSlides[j];
                const lastCarouselSlideProduct = currentCarouselSlide.getElementsByClassName('product')[0];
                lastCarouselSlideProduct.setAttribute('slideindex', j);
                lastCarouselSlideProduct.setAttribute('spotlightindex', i);
                lastCarouselSlideProduct.addEventListener('keydown', function (e) {
                    const code = (e.keyCode ? e.keyCode : e.which);
                    if (e.shiftKey && code === 9) {
                        const slideIndex = parseInt(this.getAttribute('slideindex'));
                        const spotlightIndex = parseInt(this.getAttribute('spotlightindex'));
                        setFocusOnProduct(spotlightIndex, slideIndex - 1, 3, '.carousel__previous');
                        e.preventDefault();
                    }
                });
            }
            const svgs = spotlight[i].getElementsByTagName('svg');
            for (let j = 0; j < svgs.length; j++) {
                svgs[j].setAttribute('focusable', false);
            }
        }
    }
}

function setFocusOnProduct(spotlightIndex, slideIndex, productIndex, btnClass) {
    const spotlight = document.getElementsByClassName('spotlight')[spotlightIndex];

    spotlight.querySelector(btnClass).click();
    const slides = spotlight.getElementsByClassName('carousel__slide');
    const products = slides[slideIndex].getElementsByClassName('product');
    const product = products[productIndex];
    window.setTimeout(function (){
        product.focus();
    }, 200);
}

module.exports = {
    backgroundColorChange,
    setFocusOnSpotlight
};