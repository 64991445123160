
/* eslint-disable no-magic-numbers */

import baseAnalytics from './baseAnalytics';

let isSeenTracked, isPresentTracked, isClickTracked;

const createCarouselId = () => 'brands_ms_ticker_na_hp_0';

const isScrolledIntoView = el => {
    const rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};

const trackCarouselOnPageScroll = (element, carouselId) => {
    window.addEventListener('scroll', function fn() {
        const isElementSeen = isScrolledIntoView(element);

        if(isElementSeen && !isSeenTracked) {
            window.removeEventListener('scroll', fn);
            const width = window.innerWidth;
            if(width < 1280) {
                baseAnalytics.trackEvent(carouselId, 'seenWithScroll', undefined, true);
            }
            else {
                baseAnalytics.trackEvent(carouselId, 'seen', undefined, false);
            }
            isSeenTracked = true;
        }
    });
};

const trackCarouselScrollClick = (element, carouselId) => {
    element.addEventListener('click', function fn(e) {
        if(e.target.classList.contains('carousel__next') || e.target.classList.contains('carousel__previous') || e.target.classList.contains('carousel__page-button')) {
            element.removeEventListener(e.type, fn);
            isClickTracked = true;
            baseAnalytics.trackEvent(carouselId, 'click');
        }
    });
};

const trackCarouselSwipe = (carouselId) => {
    baseAnalytics.trackEvent(carouselId, 'swipe');
};

const trackCarousel = (element) => {
    const carouselId = createCarouselId();
    const width = window.innerWidth;
    const isSeenOnLoad = isScrolledIntoView(element);

    if(!isPresentTracked) {
        const eventName = width < 1280 ? 'presentAndSeenWithScroll' : 'presentAndSeen';
        if(isSeenOnLoad) {
            baseAnalytics.trackEvent(carouselId, eventName, undefined, true);
            isPresentTracked = true;
            isSeenTracked = true;
        } else {
            baseAnalytics.trackEvent(carouselId, 'present');
            trackCarouselOnPageScroll(element, carouselId);
        }
    }

    if(!isClickTracked) {
        trackCarouselScrollClick(element, carouselId);
    }

    return carouselId;
};

const analyticsFunctions = { trackCarousel, trackCarouselSwipe};
export default analyticsFunctions;