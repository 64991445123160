export function automaticTransition() {

    window.addEventListener('DOMContentLoaded', function () {
        const stage = document.getElementsByClassName('proposition-banner')[0];
        const arr = document.getElementsByClassName('proposition-banner__link');
        function fadeComplete() {
            stage.appendChild(arr[0]);
        }
        for (let i = 0; i < arr.length; i++) {
            arr[i].addEventListener('animationend', fadeComplete, false);
        }
    }, false);
}
