/* eslint-disable class-methods-use-this, consistent-return, no-magic-numbers, no-else-return, no-extra-parens, no-invalid-this */
import ProductListCarousel from '@mands/mns-fe-pattern-library/lib/mns-fe-patterns/components/product-list-carousel';
import { waitUntil } from '../../../../common/utils';
import analytics from './analytics';
const delay = 200;
const timeOut = 5000;
let isSwipeTracked = false;
var durationPerImage = 2;
var isPlayIconVisible = true;
var desktopScreenWidth = 1280;

function setFocusOnBrand(slideIndex, brandIndex, btnClass) {
    const shopbyBrandCarousel = document.getElementById('productCarouselshopbyBrand');
    shopbyBrandCarousel.querySelector(btnClass).click();
    const slides = shopbyBrandCarousel.getElementsByClassName('carousel__slide');
    const brands = slides[slideIndex].getElementsByClassName('brand');
    const brand = brands[brandIndex];
    setTimeout(() => {
        brand.focus();
    }, delay);
}
export default class ShopbyBrandCarousel {
    constructor() {
        this.initialiseCarousel();
        this.bindResizeEvent();
    }

    bindResizeEvent() {
        var obj = this;
        var mobileElement = document.getElementById('mobileproductCarouselshopbyBrand');
        window.addEventListener('resize', function() {
            obj.deviceType = obj.isTouchDevice() ? 't' : 'd';
            if(obj.deviceType === 't') {
                mobileElement.classList.toggle('toggle-display');
                obj.hidePlayPauseControl();
            }
        }, false);
    }

    handleKeyBoardEvents() {
        const carouselSlides = this.shopbyBrandCarousel.getElementsByClassName('carousel__slide');
        const carouselHeader = document.querySelector('.shopby-brand__carousel .heading-d');
        const totalCarouselSlides = carouselSlides.length;
        const nextBtn = this.shopbyBrandCarousel.querySelector('.carousel__next');
        const previousBtn = this.shopbyBrandCarousel.querySelector('.carousel__previous');
        const slidePips = this.shopbyBrandCarousel.querySelectorAll('.carousel__page-button');
        if(carouselHeader) {
            carouselHeader.setAttribute('tabIndex', 0);
        }
        if(nextBtn && previousBtn) {
            nextBtn.setAttribute('tabIndex', -1);
            previousBtn.setAttribute('tabIndex', -1);
        }
        if(slidePips) {
            [...slidePips].forEach(e => e.setAttribute('tabIndex', -1));
        }
        [...carouselSlides].forEach((element, index) => {
            if (index !== (totalCarouselSlides - 1)) {
                const currentCarouselSlide = carouselSlides[index];
                const lastCarouselSlideProduct = currentCarouselSlide.getElementsByClassName('brand')[5];
                lastCarouselSlideProduct.setAttribute('slideindex', index);
                lastCarouselSlideProduct.addEventListener('keydown', function (e) {
                    const code = (e.keyCode ? e.keyCode : e.which);
                    if (!(e.shiftKey) && code === 9) {
                        const slideIndex = parseInt(this.getAttribute('slideindex'));
                        setFocusOnBrand(slideIndex + 1, 0, '.carousel__next');
                        e.preventDefault();
                    }
                });
            }
            if (index > 0) {
                const currentCarouselSlide = carouselSlides[index];
                const lastCarouselSlideProduct = currentCarouselSlide.getElementsByClassName('brand')[0];
                lastCarouselSlideProduct.setAttribute('slideindex', index);
                lastCarouselSlideProduct.addEventListener('keydown', function (e) {
                    const code = (e.keyCode ? e.keyCode : e.which);
                    if (e.shiftKey && code === 9) {
                        const slideIndex = parseInt(this.getAttribute('slideindex'));
                        setFocusOnBrand(slideIndex - 1, 5, '.carousel__previous');
                        e.preventDefault();
                    }
                });
            }
        });
        const svgs = this.shopbyBrandCarousel.getElementsByTagName('svg');
        [...svgs].forEach(e => e.setAttribute('focusable', false));
    }

    initialiseCarousel() {
        this.analyticsWrapperElement = document.querySelector('.shopby-brand__carousel');
        this.shopbyBrandCarousel = document.getElementById('productCarouselshopbyBrand');
        if (this.shopbyBrandCarousel) {
            new ProductListCarousel({ id: 'productCarouselshopbyBrand' });
            this.setBrandTiles();
            this.handleKeyBoardEvents();
            waitUntil(this.checkUtag.bind(this), this.invokeAnalytics.bind(this), delay, timeOut);
            waitUntil(this.enableAutoScroll.bind(this), this.setAutoScroll.bind(this), delay, timeOut);
        }
    }

    setBrandTiles() {
        this.mobileCarouselContainer = document.querySelector('#mobileproductCarouselshopbyBrand');
        this.mobileCarousel = document.querySelector('#mobileproductCarouselshopbyBrand .product__carousel-slides-wrapper');
        this.carouselHeaderContainer = document.querySelector('.shopby-brand__carousel .plc__header');
        this.carouselHeader = document.querySelector('.shopby-brand__carousel .heading-d');
        this.allBrands = [...document.querySelectorAll('#productCarouselshopbyBrand .carousel__slide-product')];
        this.uniqueBrands = [...new Set(this.allBrands.map(e => e.outerHTML))];
        this.mobileCarousel.innerHTML = this.uniqueBrands.reduce((html, brand) => html + brand, '');
    }

    setBrandCarouselWidthForMobile(n) {
        if (window.innerWidth < 768) {
            return `calc((0px + (46vw + 0px) * ${n}) * 2)`;
        } else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
            return `calc((0px + (25vw + 0px) * ${n}) * 2)`;
        } else if (window.innerWidth >= 1024) {
            return `calc((8px + (16.66vw + 16px) * ${n}) * 2)`;
        }
    }

    enableAutoScroll() {
        window.enableAutoScroll = true; // will be removed once AB test is set up
        if(window.enableAutoScroll) {
            return true;
        }
        return false;
    }

    setWrapperElements() {
        this.parentNode = this.mobileCarouselContainer.parentNode;
        this.wrapper = document.createElement('div');
        this.wrapper.classList.add('mobile-carousel-wrapper');
        this.parentNode.replaceChild(this.wrapper, this.mobileCarouselContainer);
        this.wrapper.appendChild(this.mobileCarouselContainer);
        this.mobileCarouselSibling = this.mobileCarousel.cloneNode(true);
        this.mobileCarouselContainer.insertBefore(this.mobileCarouselSibling, this.mobileCarousel);
    }

    pauseAnimationHandler() {
        if(this.deviceType === 't') {
            this.carouselHeaderContainer.setAttribute('aria-label', 'Shop by brand carousel, Auto scroll paused. Swipe to interact with the carousel');
        }
        this.mobileCarouselContainer.classList.remove('infinite-scroll');
        this.mobileCarouselContainer.style.animation = 'none';
        this.animationPaused = true;
        isPlayIconVisible = false;
        this.playPauseShopByAnimation(isPlayIconVisible);
        if (this.mobileCarousel.length > 0) {
            this.mobileCarousel[1].classList.add('shopby-brand-second-list'); // Adding class for ipad portrait mode view issues
        }
    }

    isTouchDevice() {
        return 'ontouchstart' in window || navigator.maxTouchPoints;
    }

    setAutoScroll() {
        this.setWrapperElements();
        this.mobileCarousel = document.querySelectorAll('#mobileproductCarouselshopbyBrand .product__carousel-slides-wrapper');
        if (this.mobileCarousel.length > 0) {
            Array.prototype.slice.call(this.mobileCarousel[1].querySelectorAll('a')).forEach(element => element.setAttribute('tabIndex', -1));
        }
        this.mobileCarouselContainer.classList.add('animation');
        this.mobileCarouselContainer.classList.add('infinite-scroll');
        // this.mobileCarouselContainer.style.width = this.setBrandCarouselWidthForMobile(this.uniqueBrands.length);
        // Updating Animation Duration based on Brand Count - starting
        this.calcAnimationDuration = this.uniqueBrands.length * durationPerImage;
        this.mobileCarouselContainer.style.animationDuration = this.calcAnimationDuration+'s';
        // Updating Animation Duration based on Brand Count - Ending
        this.deviceType = this.isTouchDevice() ? 't' : 'd';
        this.playPauseElement = document.querySelector('.shopby-brand-control');
        if (this.deviceType === 't') {
            this.carouselHeaderContainer.setAttribute('tabIndex', 0);
            this.carouselHeader.setAttribute('aria-hidden', true);
            this.carouselHeader.setAttribute('tabIndex', -1);
            this.carouselHeaderContainer.setAttribute('aria-label', 'Shop by brand carousel. Double tap to pause auto scrolling carousel');
            this.carouselHeader.addEventListener('click', () => {
                this.pauseAnimationHandler(true);
            });

            this.hidePlayPauseControl();
        }else{
            this.playPauseElement.style.display = 'none';
        }
        this.playPauseElement.addEventListener('touchstart', (e) => {
            e.preventDefault();
            isPlayIconVisible = !isPlayIconVisible;
            this.playPauseShopByAnimation(isPlayIconVisible);
        });
        this.mobileCarouselContainer.addEventListener('touchmove', () => {
            this.pauseAnimationHandler(true);
            if(!isSwipeTracked) {
                analytics.trackCarouselSwipe(this.carouselId);
                isSwipeTracked = true;
            }
        });
    }

    hidePlayPauseControl() {
        const screenWidth  = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        this.playPauseControl = document.querySelector('.shopby-brand-control');
        if(this.playPauseControl) {
            if(screenWidth > desktopScreenWidth) {
                this.playPauseControl.style.display = 'none';
            }else {
                this.playPauseControl.style.display = 'block';
            }
        }  
    }

    playPauseShopByAnimation(isPlayIconVisible) {
        this.pauseIconElement = document.querySelector('.shopby-brand-pause-icon');
        this.playIconElement = document.querySelector('.shopby-brand-play-icon');
        this.mobileCarouselWrapper = document.querySelector('.mobile-carousel-wrapper');
        if(isPlayIconVisible)
        {
            this.mobileCarouselContainer.classList.add('infinite-scroll');
            this.mobileCarousel[1].classList.remove('shopby-brand-second-list');
            this.mobileCarouselContainer.style.removeProperty('animation');
            this.calcAnimationDuration = this.uniqueBrands.length * durationPerImage;
            this.mobileCarouselContainer.style.animationDuration = this.calcAnimationDuration+'s';
            // Updating Animation Duration based on Brand Count - Ending
            this.pauseIconElement.style.display = 'block';
            this.playIconElement.style.display = 'none';
        }else{
            this.mobileCarouselWrapper.style.overflowX = 'scroll';
            this.mobileCarouselContainer.style.animationPlayState = 'paused';
            this.pauseIconElement.style.display = 'none';
            this.playIconElement.style.display = 'block';
        }
    }

    checkUtag() {
        if (typeof utag !== 'undefined') {
            return true;
        }
    }

    invokeAnalytics() {
        this.carouselId = analytics.trackCarousel(this.analyticsWrapperElement);
    }
}

new ShopbyBrandCarousel();
