/*eslint-disable */
import {isVisible, hasClass} from '../../../../common/common';
import {matchMedia} from '../../../../common/matchMedia';
let counter = 0;
let prevCounter = 0;
const wrpperElm = document.getElementsByClassName('hero-slider')[0] ? document.getElementsByClassName('hero-slider')[0] : '';
const slides = wrpperElm ? wrpperElm.getElementsByClassName('new-hero-banner__link') : '';
const paginationData = wrpperElm ? wrpperElm.getElementsByClassName('new-hero-banner__pagination') : '';
const pagination = paginationData ?  paginationData[0] : '';
const paginationButtons = pagination ? pagination.getElementsByClassName('pager-item') : '';
const progressData = wrpperElm ? wrpperElm.getElementsByClassName('new-hero-banner__progress-bar'): '';
let progressBar = progressData ? progressData[0]: '';
const progressDataMobile = wrpperElm ? wrpperElm.getElementsByClassName('new-hero-banner__progress-bar-mobile') : '';
let progressBarMobile = progressDataMobile ? progressDataMobile[0]: '';
const slidesWrapper = wrpperElm ? wrpperElm.getElementsByClassName('new-hero-banner__large-wrapper')[0] : '';
const control = wrpperElm ? wrpperElm.getElementsByClassName('new-hero-banner__icon-wrapper')[0] : '';
const time = 5000;
const containerWidth = 100;
const slidesCount = slides.length;
const progressBarTime = 25;
let navigation = 0;
const progressBarDelay = 0;
const pauseIcon = wrpperElm ? wrpperElm.getElementsByClassName('new-hero-banner__pause-icon')[0] : '';
const playIcon = wrpperElm ? wrpperElm.getElementsByClassName('new-hero-banner__play-icon')[0] : '';
const media = matchMedia(5, 4, 3, 2, 1);
let width = document.body.clientWidth;
const desktop = [5, 4];
let AutomationStarted = 0;
let AnimationStarted = 0;
var animating = false;
let timerForLoop, timer, deltaPos, movePosFlag, startPos, movePos, traslateWidth;
var pauseSlider = false;
var xDown = null;                                                        
var yDown = null;

function includes(array, search) {
    const result = array.filter(element => element === search);
    return result > 0;
}

const Animation = {
    id: null,
    start: function(callback) {
        this.id = window.setInterval(() => {
            callback();
        }, progressBarTime);
        return this.id;
    },
    reset: function() {
        clearInterval(this.id);
        this.id = null;
    }
};

const Automation = {
    id: null,
    start: function() {
        this.id = setInterval(() => {            
            counter++;
            navigateSlider();
        }, time);
    },
    reset: function() {
        clearInterval(this.id);
        this.id = null;
    }
};


const ProgressBar = {
    target: null,
    widthIncrement: 0.50,
    currentWidth: 0,
    animationId: null,
    element: null,

    reset() {
        this.currentWidth = 0;
        this.stop();
    },

    start() {
        this.animationId = Animation.start(this.move);
    },

    appendElement() {
        const bar = document.createElement('div');
        bar.className = 'bar';

        if(width < 1024) {
            progressBarMobile.appendChild(bar);
        } else {
            progressBar.appendChild(bar);
        }
        ProgressBar.target = bar;
        if(pauseSlider) {
            pauseIcon.style.display = 'none';
            playIcon.style.display = 'inline-block';
            pauseSlider = false;
            this.reset();
        } else {
            if(isVisible(pauseIcon)) {
                this.start();
            }
        }
    },
    removeElement() {
        const child = this.element.querySelector('.bar');
        this.element.removeChild(child);
    },

    control(slideCounter=undefined) {
        if(counter !== 0) {
            if(width < 1024) {
                if(slideCounter) {
                    progressDataMobile[slideCounter].classList.remove('hidden');
                } else {
                    progressDataMobile[counter].classList.remove('hidden');
                }
            } else {
                progressData[counter].classList.remove('hidden');
            }
        } else {
            if(width < 1024) {
                if(slideCounter) {
                    progressDataMobile[slideCounter].classList.remove('hidden');
                } else {
                    progressDataMobile[0].classList.remove('hidden');
                }
            } else {
                progressData[0].classList.remove('hidden');
            }
        }
        if(width < 1024) {
            if(slideCounter) {
                progressBarMobile = progressDataMobile[slideCounter];
            } else {
                progressBarMobile = progressDataMobile[counter];
            }
        } else {
            progressBar = progressData[counter];
        }

        this.appendElement();
    },

    move() {
        var self = ProgressBar;
        if (self.currentWidth >= containerWidth) {
            counter++;
            if(width < 1024) {
                navigateSlider();
            } else {
                navigateSliderWithFadeEffect();
            }
        }

        if(navigation === 0) {
            self.currentWidth += self.widthIncrement;
            self.target.style.width = self.currentWidth + '%';
        }
    },

    stop() {
        Animation.reset(this.animationId);
        this.animationId = null;
    }

};

const paginationCtrl = {
    addElement: function() {
        for(let i = 0;i < slidesCount;i++) {
            const elem = document.createElement('span');
            elem.classList.add('pager-item');
            const childElem = document.createElement('span');
            elem.onclick = (function(i) {
                return function() {
                    counter = i;
                    if(width < 1024) {
                        pauseSlider = true
                        navigateSlider();
                    } else {
                        pauseSlider = true;
                        navigateSliderWithFadeEffect();
                    }
                };
            })(i);
            if (i === 0) {
                elem.classList.add('sel');
            }
            elem.appendChild(childElem);
            pagination.appendChild(elem);
        }
    },
    removeElement: function() {
        const paginationChildElement = pagination.querySelectorAll('span.pager-item');
        if(paginationChildElement.length > 0) {
            Array.prototype.slice.call(paginationChildElement).forEach(element => {
                pagination.removeChild(element);
            });
        }
    }
};

function counterReset() {
    if (counter === slidesCount) {
        counter = 0;
    }
    else if (counter < 0) {
        counter = slidesCount - 1;
    }
}

function navigateSliderWithFadeEffect() {
    counterReset();

    if (progressBar) {
        ProgressBar.reset();
    }

    slides[prevCounter].classList.remove('fade-in');

    navigation++;

    ProgressBar.element = progressData[prevCounter];
    ProgressBar.element.classList.add('hidden');

    const timeout = setTimeout(function() {
        pauseIcon.parentElement.style.cursor = 'pointer';
        ProgressBar.removeElement();
        ProgressBar.control();
        navigation = 0;
        clearTimeout(timeout);
    }, progressBarDelay);

    slides[counter].classList.add('fade-in');
    paginationButtons[prevCounter].classList.remove('sel');
    paginationButtons[counter].classList.add('sel');
    prevCounter = counter;
}

function changeStyle() {
    slidesWrapper.style.transform = `translate3d(-${width*(counter+1)}px, 0px, 0px)`;
}

function loopMove(slideCount, translate) {
    counter = slideCount;

    ProgressBar.control(counter+1);

    slidesWrapper.style.transform = `translate3d(${translate}px, 0px, 0px)`;
    timerForLoop = setTimeout(function () {
        slidesWrapper.style.transitionDuration = '0s';
        changeStyle();
        navigation = 0;
        clearTimeout(timerForLoop);
        animating = false;
    }, 400);
}

function navigateSlider() {
    if (progressBarMobile) {
        ProgressBar.reset();

        if(progressBarMobile.querySelector('.bar')) {
            ProgressBar.element = progressBarMobile;
            ProgressBar.removeElement();
        }
    }

    navigation++;

    slidesWrapper.style.transitionDuration = '0.3s';
    animating = true;
    if (counter === slidesCount) {
        const translate = -width*(slidesCount+1);
        loopMove(0, translate);
    } else if(counter < 0) {
        loopMove(slidesCount - 1, 0);
    } else {
        ProgressBar.control(counter+1);
        changeStyle();
        
        timer = setTimeout(function(){ 
            slidesWrapper.style.transitionDuration = '0s';
            navigation = 0;
            clearTimeout(timer);
            animating = false;
        }, 400);
    }

    paginationButtons[prevCounter].classList.remove('sel');
    paginationButtons[counter].classList.add('sel');
    prevCounter = counter;
}

function updateWidth() {
    slidesWrapper.style.width = `${width*(slidesCount+2)}px`;
    changeStyle();
    Array.prototype.slice.call(slidesWrapper.querySelectorAll('.new-hero-banner__link')).forEach(element => {
        element.style.display = 'block';
        element.style.width = width + 'px';
    });
}

function cloneElement() {
    const firstElement = slidesWrapper.querySelectorAll('.new-hero-banner__link')[0].cloneNode(true);
    slidesWrapper.appendChild(firstElement);
    const lastElement = slidesWrapper.querySelectorAll('.new-hero-banner__link')[slidesCount-1].cloneNode(true);
    slidesWrapper.insertBefore(lastElement, slidesWrapper.firstChild);
    slidesWrapper.firstChild.classList.add('cloned');
    slidesWrapper.lastChild.classList.add('cloned');
    updateWidth();
}

function triggerEvent( elem, event ) {
    if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
        var evt = document.createEvent('UIEvents');
        evt.initUIEvent(event, true, false, window, 0);
        window.dispatchEvent(evt);
    } else {
        var clickEvent = new Event( event );
        elem.dispatchEvent( clickEvent );
    }
}

function mobileReset() {
    ProgressBar.reset();
    counter = 0;
    progressBar = progressData[counter];
    progressBar.classList.remove('hidden');
    progressBarMobile = progressDataMobile[counter];
    progressBarMobile.classList.remove('hidden');
    ProgressBar.element = null;
    ProgressBar.target = null;
    ProgressBar.currentWidth = 0;
    clearTimeout(timerForLoop);
    clearTimeout(timer);

    paginationButtons[prevCounter].classList.remove('sel');
    paginationButtons[counter].classList.add('sel');

    slidesWrapper.removeAttribute('style');
    Array.prototype.slice.call(progressData).forEach(function(element) {
        if(element.childNodes.length !== 0) {
            const child = element.querySelector('.bar');
            element.removeChild(child);
        }
    });
    Array.prototype.slice.call(progressDataMobile).forEach(function(element) {
        if(element.childNodes.length !== 0) {
            const child = element.querySelector('.bar');
            element.removeChild(child);
        }
    });
}

function desktopReset() {
    ProgressBar.reset();
    counter = 0;
    progressBar = progressData[counter];
    progressBar.classList.remove('hidden');
    progressBarMobile = progressDataMobile[counter];
    progressBarMobile.classList.remove('hidden');
    ProgressBar.element = null;
    ProgressBar.target = null;
    ProgressBar.currentWidth = 0;
    clearTimeout(timerForLoop);
    clearTimeout(timer);

    paginationButtons[prevCounter].classList.remove('sel');
    paginationButtons[counter].classList.add('sel');

    slidesWrapper.removeAttribute('style');
    slidesWrapper.style.backgroundColor = '#000';
    Array.prototype.slice.call(slidesWrapper.querySelectorAll('.cloned')).forEach(element => {
        slidesWrapper.removeChild(element);
    });
    const childElements = slidesWrapper.querySelectorAll('.new-hero-banner__link');
    Array.prototype.slice.call(childElements).forEach((element, index) => {
        element.removeAttribute('style');
        //element.querySelectorAll(':scope > .new-hero-banner__button')[0].style.display = 'none';
        if(hasClass(element, 'fade-in') && index !== 0) {
            element.classList.remove('fade-in');
        }
        if(index === 0) {
            element.classList.add('fade-in');
        }
    });
    Array.prototype.slice.call(progressData).forEach(function(element) {
        if(element.childNodes.length !== 0) {
            const child = element.querySelector('.bar');
            element.removeChild(child);
        }
    });
    Array.prototype.slice.call(progressDataMobile).forEach(function(element) {
        if(element.childNodes.length !== 0) {
            const child = element.querySelector('.bar');
            element.removeChild(child);
        }
    });
}

function updateWrapperMinWidthAndHeight(minWidth, minHeight) {
    slidesWrapper.style.minWidth = `${minWidth}px`;
    slidesWrapper.style.minHeight = `${minHeight}px`;
}

export function heroSlider() {
    const img = slides[0].querySelector('.new-hero-banner__lg-image img');
    if(width < 1024) {
        triggerEvent(img, 'load');
    }
    
    if(includes(desktop, media.currentPort) && progressBar) {
        if (pagination) {
            paginationCtrl.addElement();
        }
        ProgressBar.appendElement();
    } else {
        cloneElement();

        changeStyle();

        if (pagination) {
            paginationCtrl.addElement();
        }

        navigateSlider();
    }

    function pauseIconCode(style) {
        pauseIcon.style.display = style.pauseIconDisplay;
        playIcon.style.display = style.playIconDisplay;
    }

    control.addEventListener('click', function(event) {
        event.preventDefault();
        if(!isVisible(pauseIcon)) {
            pauseIconCode({pauseIconDisplay: 'inline-block', playIconDisplay: 'none'});
            // includes(desktop, media.currentPort) ? ProgressBar.start() : Automation.start();
            if(includes(desktop, media.currentPort)) {
                ProgressBar.start();
            } else {
                ProgressBar.start();
            }
        } else {
            pauseIconCode({pauseIconDisplay: 'none', playIconDisplay: 'inline-block'});
            // includes(desktop, media.currentPort) ? ProgressBar.stop() : Automation.reset();
            if(includes(desktop, media.currentPort)) {
                ProgressBar.stop();
            } else {
                ProgressBar.stop();
                animating = false;
            }            
        }
    });

    function deviceChange() {
        width = document.body.clientWidth;
        if(width >= 1024) {
            if(AnimationStarted === 0) {
                AutomationStarted = 0;
                desktopReset();
                if(Animation.id) {
                    ProgressBar.stop();
                }
                Automation.reset();
                paginationCtrl.removeElement();
                slides[counter].classList.add('fade-in');
                prevCounter = 0;
                counter = 0;
                pauseIconCode({pauseIconDisplay: 'inline-block', playIconDisplay: 'none'});
                if (pagination && pagination.querySelectorAll('span.pager-item').length === 0) {
                    paginationCtrl.addElement();
                }
                ProgressBar.appendElement();
                AnimationStarted++;
            }
        } else {
            updateWidth();
            triggerEvent(img, 'load');
            if(AutomationStarted === 0) {
                mobileReset();
                triggerEvent(img, 'load');
                cloneElement();
                AnimationStarted = 0;
                if(Animation.id) {
                    ProgressBar.stop();
                }
                paginationCtrl.removeElement();
                prevCounter = 0;
                counter = 0;
                pauseIconCode({pauseIconDisplay: 'inline-block', playIconDisplay: 'none'});
                if (pagination && pagination.querySelectorAll('span.pager-item').length === 0) {
                    paginationCtrl.addElement();
                }
                // Automation.start();
                ProgressBar.control(counter+1);
                AutomationStarted++;
            }
        }
    }

    media.addListener(deviceChange);

    window.addEventListener("orientationchange", deviceChange);

    function getTouches(evt) {
        return evt.touches ||             // browser API
               evt.originalEvent.touches; // jQuery
    }

    slidesWrapper.addEventListener('touchstart', function(e) {
        if(animating) return;
        const firstTouch = getTouches(e)[0];                                      
        xDown = firstTouch.clientX;                                      
        yDown = firstTouch.clientY;
    }, false);

    slidesWrapper.addEventListener('touchmove', function(e) {
        if ( ! xDown || ! yDown ) {
            return;
        }
    
        var xUp = e.touches[0].clientX;                                    
        var yUp = e.touches[0].clientY;
    
        var xDiff = xDown - xUp;
        var yDiff = yDown - yUp;
    
        if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
            if ( xDiff > 0 ) {
                /* left swipe */ 
                handleswipe('left');
            } else {
                /* right swipe */
                handleswipe('right');
            }                       
        } else {
            if ( yDiff > 0 ) {
                /* up swipe */ 
            } else { 
                /* down swipe */
            }                                                                 
        }
        /* reset values */
        xDown = null;
        yDown = null;
    }, {passive: false});
    
    slidesWrapper.addEventListener('touchend', function(e) {
                
    });

    function handleswipe(dir) {
        if(isVisible(pauseIcon)) {
            triggerEvent( control, 'click' );
        }

        if(dir == 'left') {
            counter++;
        } else {
            counter--;
        }
        navigateSlider();
    }

    img.addEventListener('load', function(e) {
        if(this.offsetHeight < this.offsetWidth && width < 1024) {
            slidesWrapper.style.height = `${this.offsetHeight}px`;
            updateWrapperMinWidthAndHeight(this.offsetWidth, img.offsetHeight);
        }
        if(width >= 1024) {
            slidesWrapper.style.backgroundColor = '#000';
        }
    });
    
    if(width >= 1024) {
        AnimationStarted++;
    } else {
        AutomationStarted++;
    }

}