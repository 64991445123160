import slideUp from './slideUp';
export default class countdown {
    constructor(elm) {
        this.el = elm;
        const date = this.el.getAttribute('data-time');
        this.countDownDate = this.formatDate(date);
        this.updateTimer();
    }

    formatDate(date) {
        const countDownDate = new Date(date).getTime();
        return countDownDate;
    }

    getTimeRemaining() {
        const now = new Date().getTime();
        const distance = this.countDownDate - now;
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        return {days, hours, minutes, seconds, distance};
    }

    updateTimer() {
        let x = setInterval(() => {
            const {days , hours, minutes, seconds, distance} = this.getTimeRemaining();
            const dys = days.toString().length >= 2 ? days : `0${days}`
            this.el.innerHTML = `${dys}<span>d</span> ${('0'+hours).slice(-2)
        }<span>h</span> ${('0'+minutes).slice(-2)}<span>m</span> ${('0'+seconds).slice(-2)}<span>s</span>`;
             this.el.parentElement.style.visibility = 'visible';
            if (distance < 1000) {
                clearInterval(x);
                slideUp(this.el.parentElement, distance);
            }
        }, 1000);
    }
} 